window.mobileCheck = function () {
    let check = false;
    (function (a) {
        if (/(android|ipad|playbook|silk|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
            check = true;
        }
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};

window.updateViewportDimensions = function () {
    let w = window, d = document, e = d.documentElement, g = d.getElementsByTagName('body')[0],
        x = w.innerWidth || e.clientWidth || g.clientWidth, y = w.innerHeight || e.clientHeight || g.clientHeight;
    return {width: x, height: y};
};

window.scrollToTarget = function (target, offset = 140) {
    window.searchAndClosureSelector(target, (item) => {
        const elementPosition = item.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - offset;
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
        });
    }, false, true);
}

window.sleep = function (ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
};

window.hasClass = function (ele, cls) {
    if (typeof ele != 'undefined' && ele != null) {
        return ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
    }
    return false;
};

window.getPushEvent = function (num) {
    let events = {
        0: 'undefined',
        1: 'casinolist-vert-logo',
        2: 'casinolist-vert-bonus',
        3: 'casinolist-vert-btn',
        4: 'casinolist-logo',
        5: 'casinolist-bonus',
        6: 'casinolist-btn',
        7: window.hasClass(document.body, 'archive') ? 'casinotop-logo' : 'casinosidebar-logo',
        8: window.hasClass(document.body, 'archive') ? 'casinotop-bonus' : 'casinosidebar-bonus',
        9: window.hasClass(document.body, 'archive') ? 'casinotop-btn' : 'casinosidebar-btn',
        10: 'top-logo',
        11: 'top-bonus',
        12: 'top-btn',
        13: 'bonuslist-logo',
        14: 'bonuslist-bonus',
        15: 'bonuslist-btn',
        16: 'ongame',
        17: window.hasClass(document.getElementById('gameContainer'), 'popup-content') ? 'undergame-fullscreen' : 'undergame',
        18: 'gamedetails-btn',
        19: 'restricted-block',
        20: 'restricted-continue',
        21: 'casinodetails-btn',
        22: 'casinodetails-website',
        23: 'banner',
        24: 'banner-cta',
    };

    return events[num];
};

window.setCookie = function (key, value, expiry) {
    let expires = new Date();

    expires.setTime(expires.getTime() + expiry * 24 * 60 * 60 * 1000);
    document.cookie = key + '=' + value + ';path=/' + ';expires=' + expires.toUTCString();
};

window.issueReportVerifyMail = function (email) {
    let pattern = /\S+@\S+\.\S+/,
        button = document.getElementById('issueReportBtn'),
        verify = pattern.test(email);

    button.disabled = !verify;
    button.classList[verify ? "remove" : "add"]('btn-grey');
    button.classList[verify ? "add" : "remove"]('btn-green');
    return verify;
};

window.getRecaptchaV3 = function () {
    window.searchAndClosureSelector('recatok', (item) => {
        if (item.hasAttribute('data-key') && typeof grecaptcha != 'undefined' && grecaptcha != null) {
            grecaptcha.ready(function () {
                grecaptcha.execute(item.getAttribute('data-key')).then(function (token) {
                    item.value = token;
                });
            });
        }
    }, false, true);
};

window.popoverShow = function (btn) {
    let bx = btn.nextElementSibling;
    bx.classList[bx.classList.contains('opened') ? 'remove' : 'add']('opened');
};

window.openFullscreen = function (id) {
    let el = document.getElementById(id);
    el.style.display = 'block';
    if (el.requestFullscreen) {
        el.requestFullscreen();
    } else if (el.webkitRequestFullscreen) {
        /* Safari */
        el.webkitRequestFullscreen();
    } else if (el.msRequestFullscreen) {
        /* IE11 */
        el.msRequestFullscreen();
    }
};

window.objectToUrlQuery = function (obj, obj_key = null) {
    let out = [];
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            let push_key = obj_key === null ? key : obj_key + '[]';
            if (typeof obj[key] === 'object') {
                out.push(objectToUrlQuery(obj[key], key));
            } else {
                out.push(encodeURIComponent(push_key) + '=' + encodeURIComponent(obj[key]));
            }
        }
    }

    return out.join('&');
};

window.tgOpen = function (button) {
    let parent = button.closest('.tg'), child = button.firstChild, text = child.getAttribute('data-text');

    parent.classList[window.hasClass(parent, 'opened') ? 'remove' : 'add']('opened');
    child.setAttribute('data-text', child.textContent);
    child.innerHTML = text;
};

window.sendRequest = function (data, method, url, headers, onloadFunction = () => {}, alwaysFunction = () => {}, async = true) {
    let xhr = new XMLHttpRequest(), isJson = false;
    xhr.open(method, url, async);

    if (Object.keys(headers).length > 0) {
        for (const key in headers) {
            if (key === "Content-type" && headers[key].includes("json")) {
                isJson = true;
            }

            xhr.setRequestHeader(key, headers[key]);
        }
    }

    xhr.send(isJson ? JSON.stringify(data) : objectToUrlQuery(data));
    xhr.onload = () => {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
            onloadFunction(xhr);
        }
    };
    xhr.onloadend = () => {
        alwaysFunction();
    };
};

window.createElementFromHTML = function (htmlString, element = "div") {
    let div = document.createElement(element);
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
}

window.searchAndClosureSelector = function (selector, closure = () => {
}, is_array = false, is_id = false, search_in = null) {
    let items;
    if (search_in === null) {
        items = is_id ? document.getElementById(selector) : is_array ? document.querySelectorAll(selector) : document.querySelector(selector);
    } else {
        items = is_id ? search_in.getElementById(selector) : is_array ? search_in.querySelectorAll(selector) : search_in.querySelector(selector);
    }

    if (is_array && items.length) {
        items.forEach((el) => {
            closure(el);
        });
    }
    if (!is_array && items) {
        closure(items);
    }
    return null;
};

window.doAvial = function (pid = null, timeAwait = 5000) {
    window.searchAndClosureSelector('avialContainer', (item) => {
        item.style.display = "flex";
    }, false, true);

    window.awaitClosure(timeAwait, () => {
        window.searchAndClosureSelector('avialContainer', (item) => {
            item.classList.add('open');
        }, false, true);

        document.body.classList.add('no-scroll');

        if (parseInt(pid) > 0) {
            window.searchAndClosureSelector('avialAnyway', (item) => {
                item.setAttribute('data-pid', pid);
            }, false, true);
        }
    });
};

window.awaitClosure = async function (time = 0, closure = () => {
}) {
    await sleep(time);
    closure();
};

window.nextAll = function* (e, selector) {
    while ((e = e.nextElementSibling)) {
        if (e.matches(selector)) {
            yield e;
        }
    }
};

window.nextSibling = function (elem, selector) {
    // Get the next element
    let nextElem = elem.nextElementSibling;

    // If there's no selector, return the next element
    if (!selector) {
        return nextElem;
    }

    // Otherwise, check if the element matches the selector
    if (nextElem && nextElem.matches(selector)) {
        return nextElem;
    }

    // if it's not a match, return null
    return null;
};

window.prevSibling = function (elem, selector) {
    // Get the next element
    let prevElem = elem.previousElementSibling;

    // If there's no selector, return the next element
    if (!selector) {
        return prevElem;
    }

    // Otherwise, check if the element matches the selector
    if (prevElem && prevElem.matches(selector)) {
        return prevElem;
    }

    // if it's not a match, return null
    return null;
};

window.toggleElement = function (element, display_style = "block") {
    let style = window.getComputedStyle(element);
    element.style.display = element.style.display === "none" || style.display === "none" ? display_style : "none";
}

window.wrap = function (el, wrapper) {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
}

window.clickToCasino = function (button) {
    let object = {},
        data = {
            pid: parseInt(button.getAttribute('data-pid')),
        };

    if (typeof refdata !== 'undefined') {
        data.refdata = refdata;
    }

    window.sendRequest(data, 'POST', hexjs.home + '/wp-content/plugins/slots-bundle/includes/geotarget.callback.php', {
        Accept: 'application/json', 'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
    }, (xhr) => {
        object = JSON.parse(xhr.response);

        if (object.link.length > 1) {
            let num = button.hasAttribute('data-ga') ? parseInt(button.getAttribute('data-ga')) : 0,
                ct_ga = [1, 2, 3, 4, 5, 6],
                ctt_ga = [7, 8, 9];

            if (typeof dataLayer != 'undefined' && dataLayer != null) {
                let pageType = document.body.className.split(/\s+/).find((e) => /^ga__(.*)$/.test(e)),
                    listTypes = {
                        regular_casino_list_table: [4, 5, 6],
                        large_casino_list_table: [1, 2, 3],
                        top_3_table: [7, 8, 9],
                        data_table: [18, 21, 22],
                        bonus_table: [13, 14, 15],
                    },
                    buttonTypes = {
                        logo_link: [1, 4, 7, 10, 13],
                        text_link: [14, 20, 22],
                        main_ref_button: [3, 6, 9, 12, 15, 16, 17, 18, 19, 21, 24],
                    },
                    positions = {
                        in_content: [1, 2, 3, 4, 5, 6, 13, 14, 15, 24],
                        top_3_casinos: [7, 8, 9],
                        review_head: [10, 12],
                        slot_page_bottom: [],
                        review_page_bottom: [],
                        above_demo_area: [],
                        in_demo_area: [16],
                        under_demo_area: [17],
                        review_parameters_table: [21, 22],
                        slot_parameters_table: [18],
                        casino_alternatives_popup: [19, 20],
                    },
                    listType = '',
                    buttonType = '',
                    position = '';

                pageType = pageType ? pageType.replace('ga__', '') : '';

                for (const [key, value] of Object.entries(listTypes)) {
                    if (value.indexOf(num) !== -1) {
                        listType = key;
                    }
                }
                for (const [key, value] of Object.entries(buttonTypes)) {
                    if (value.indexOf(num) !== -1) {
                        buttonType = key;
                    }
                }
                for (const [key, value] of Object.entries(positions)) {
                    if (value.indexOf(num) !== -1) {
                        position = key;
                    }
                }

                dataLayer.push({
                    event: 'click_casino',
                    is_ref_link: 'ref_link',
                    page_type: pageType,
                    list_type: listType,
                    button_type: buttonType,
                    position: position,
                    casino_name: object.name,
                    number_in_list: button.getAttribute('data-pos')
                });

                dataLayer.push({
                    event: 'GAevent',
                    eventCategory: 'Casinos',
                    eventAction: window.getPushEvent(num),
                    eventLabel: object.name,
                });

                /* pos tracking */

                if (ct_ga.indexOf(num) !== -1) {
                    dataLayer.push({
                        event: 'GAevent',
                        eventCategory: 'CasinoTable',
                        eventAction: object.name,
                        eventLabel: parseInt(button.getAttribute('data-pos')),
                    });
                } else if (ctt_ga.indexOf(num) !== -1) {
                    dataLayer.push({
                        event: 'GAevent',
                        eventCategory: 'CasinoTopTable',
                        eventAction: object.name,
                        eventLabel: parseInt(button.getAttribute('data-pos')),
                    });
                }
            }

            window.createAndSubmitForm(object);
        }
    });
}

window.createAndSubmitForm = function (object) {
    let rForm = document.createElement('form'),
        rInput = document.createElement('input'),
        rSubmit = document.createElement('input');

    rForm.method = 'POST';
    rForm.action = hexjs.home + '/redirecting.php';
    // rForm.target = '_blank';
    rForm.target = '_tab';
    rForm.rel = 'external noopener';

    rInput.type = 'hidden';
    rInput.name = 'data';
    rInput.value = JSON.stringify(object);

    rSubmit.type = 'submit';
    rSubmit.name = 'redirecting';
    rSubmit.value = '1';
    rSubmit.style.display = 'none';
    // rSubmit.formtarget = '_blank';
    rSubmit.formtarget = '_tab';
    rSubmit.rel = 'external noopener';

    rForm.appendChild(rInput);
    rForm.appendChild(rSubmit);
    document.body.appendChild(rForm);

    setTimeout(function () {
        rSubmit.click();
    }, 50);
}