window.addEventListener('DOMContentLoaded', () => {
    searchAndClosureSelector('.close', (item) => {
        item.addEventListener('click', (event) => {
            event.preventDefault();
            let id = item.getAttribute('data-id');

            searchAndClosureSelector(id, (child) => {
                child.style.display = 'none';
            }, false, true);
        });
    }, true);

    searchAndClosureSelector('.open', (item) => {
        item.addEventListener('click', (event) => {
            event.preventDefault();
            let id = item.getAttribute('data-id');

            searchAndClosureSelector(id, (child) => {
                child.style.display = 'block';
            }, false, true);
        });
    }, true);

    searchAndClosureSelector('.popup-open', (item) => {
        item.addEventListener('click', () => {
            let id = item.getAttribute('data-id');
            if (id) {
                searchAndClosureSelector(id + 'Container', (child) => {
                    child.classList.add('open');
                }, false, true);
                document.body.classList.add('no-scroll');

                if (id === 'issue') {
                    sendRequest({
                        action: 'shb_api_feedback_get',
                        nonce: hexjs.nonce,
                    }, 'POST', hexjs.ajax, {
                        'Accept': 'application/json',
                        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    }, (xhr) => {
                        let resObj = JSON.parse(xhr.response);

                        searchAndClosureSelector('#issueContainerInner ul.items', (child) => {
                            child.innerHTML = '';
                            resObj.forEach((elem) => {
                                child.innerHTML += '<li class="item"><label><input type="radio" name="issue" value="' + elem.slug + '">' + elem.name + '</label></li>';

                                searchAndClosureSelector('input', (input) => {
                                    input.addEventListener('change', () => {
                                        searchAndClosureSelector('issueReportExplane', (element) => {
                                            element.style.display = input.value === 'other' ? 'block' : 'none';
                                        }, false, true);
                                        searchAndClosureSelector('issueReportBtn', (element) => {
                                            element.removeAttribute('disabled');
                                            element.classList.remove('btn-grey');
                                            element.classList.add('btn-green');
                                        }, false, true);
                                    });
                                }, true, false, child);
                            });
                        }, false);

                        searchAndClosureSelector('issueReportBtn', (element) => {
                            element.classList.remove('none');
                        }, false, true);
                    });
                }
            }
        });
    }, true);

    searchAndClosureSelector('.popup-close', (item) => {
        item.addEventListener('click', () => {
            let id = item.getAttribute('data-id');

            if (id === 'video') {
                searchAndClosureSelector('#' + id + 'Container iframe', (video) => {
                    video.setAttribute('src', video.getAttribute('src'));
                }, false, true);
            }

            if (id) {
                searchAndClosureSelector(id + 'Container', (child) => {
                    child.classList.remove('open');
                }, false, true);
                document.body.classList.remove('no-scroll');
            }
        });
    }, true);
});
