// Events
document.querySelectorAll('[data-open]')?.forEach(function (el) {
  el.addEventListener('click', () => {
    document.querySelector(el.dataset.open).dispatchEvent(new Event('open'));
  });
});

document.querySelectorAll('[data-close]')?.forEach((el) => {
  el.addEventListener('click', () => {
    document.dispatchEvent(new Event('close'));
  });
});

document.querySelectorAll('[data-reveal]')?.forEach(function (el) {
  document.body.append(el);

  el.addEventListener('open', () => {
    el.classList.add('is-open');
  });

  function close() {
    el.classList.remove('is-open');
  }

  [el, document].forEach((e) => e.addEventListener('close', close));
});

document.querySelectorAll('[data-video]')?.forEach(function (el) {
  el.addEventListener('click', () => {
    const vId = el.dataset.video;
    const wrap = document.createElement('div');
    wrap.classList.add('video-reveal');
    const cont = document.createElement('div');
    cont.classList.add('video-reveal-cont');
    const close = document.createElement('button');
    close.classList.add('video-reveal-close', 'revealClose');

    const frame = document.createElement('iframe');
    const frameOptions = {
      src: `https://www.youtube.com/embed/${vId}`,
      frameborder: 0,
      allow:
        'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share',
      allowfullscreen: '',
    };

    Object.keys(frameOptions).forEach((k) => {
      frame.setAttribute(k, frameOptions[k]);
    });

    cont.append(frame);
    cont.append(close);
    wrap.append(cont);

    document.body.append(wrap);

    close.addEventListener('click', () => {
      wrap.remove();
    });
  });
});
