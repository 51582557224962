window.commentsFormCheck = function (e) {
    e.preventDefault();
    e.stopPropagation();

    let form = e.currentTarget.closest('form'),
        form_entries = new FormData(form),
        form_data = JSON.stringify(Object.fromEntries(form_entries)),
        data = new FormData(),
        hasError = true;

    data.append('action', "comments_formdata_check");
    data.append('nonce', hex_nonce);
    data.append('formdata', form_data);

    window.searchAndClosureSelector('i.error', (item) => {
        item.remove();
    }, true, false, form);

    (async () => {
        const rawResponse = await fetch(hexjs.ajax, {
            method: 'POST', headers: {
                // 'Accept': 'application/json',
                // 'Content-Type': 'application/json',
                'X-WP-Nonce': hex_nonce
            }, body: data
        });
        const object = await rawResponse.json();
        if (Object.keys(object).length > 0) {
            for (const key in object) {
                window.searchAndClosureSelector('[name="' + key + '"]', (item) => {
                    let next = window.nextSibling(item, 'small.error');
                    item.classList.add('error');
                    if (next) {
                        next.innerHTML = object[key].join('\n');
                    }
                }, true);
            }
            hasError = true;
        } else {
            hasError = false;
        }
        window.getRecaptchaV3();

        if (!hasError) {
            window.searchAndClosureSelector('.comments-form *[type="submit"]', (item) => {
                item.removeEventListener("click", window.commentsFormCheck, true);
            }, true);

            form.requestSubmit();
        }
    })();
};

window.commentsFormReset = function () {
    window.searchAndClosureSelector('.form-input small.error', (item) => {
        item.innerHTML = '';
    }, true);
    window.searchAndClosureSelector('.form-input .input', (item) => {
        item.classList.remove('error');
    }, true);
};

window.addEventListener('DOMContentLoaded', () => {
    let comments = document.querySelectorAll('.comments-list__items > .comments-list__item');
    window.searchAndClosureSelector('.radio-group__item input', (item) => {
        item.addEventListener('change', () => {
            let comments_copy = [...comments];
            comments.forEach((comment) => {
                comment.remove();
            });

            if (item.value === 1) {
                comments_copy.sort((a, b) => {
                    if (parseInt(a.getAttribute('data-order')) < parseInt(b.getAttribute('data-order'))) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
            }

            window.searchAndClosureSelector('.comments-list__items', (child) => {
                comments_copy.forEach((comment) => {
                    child.appendChild(comment);
                    comment.style.display = 'flex';
                });
            });
        });
    }, true);

    window.searchAndClosureSelector('.comments-list__item .item-buttons__show', (item) => {
        item.addEventListener('click', () => {
            let parent = item.closest('.comments-list__item'), text = item.getAttribute('data-text');
            parent.classList[window.hasClass(parent, 'open') ? 'remove' : 'add']('open');
            item.classList[window.hasClass(item, 'active') ? 'remove' : 'add']('active');

            item.setAttribute('data-text', item.textContent);
            item.textContent = text;
        });
    }, true);

    window.searchAndClosureSelector('.comments-list__item .item-replies-hide', (item) => {
        item.addEventListener('click', () => {
            let parent = item.closest('.comments-list__item');

            window.searchAndClosureSelector('.item-buttons__show', (child) => {
                child.click();
            }, false, false, parent);
        });
    }, true);

    window.searchAndClosureSelector('#comments .comments-leave', (item) => {
        item.addEventListener('click', () => {
            let parent = item.closest('.comments-preview');
            window.commentsFormReset();

            window.searchAndClosureSelector('commentsForm', (child) => {
                child.classList.add('none');
                parent.after(child);
                child.classList.remove('none');
                child.style.display = 'block';
                window.scrollTo({
                    top: child.offsetTop, behavior: 'smooth',
                });
            }, false, true);
        });
    }, true);

    window.searchAndClosureSelector('.item-buttons__reply', (item) => {
        item.addEventListener('click', () => {
            let parent = item.closest('.comments-list__item');
            window.commentsFormReset();

            window.searchAndClosureSelector('commentsForm', (child) => {
                window.searchAndClosureSelector('input[name="comment_parent"]', (input) => {
                    input.value = item.getAttribute('data-comments-id');
                }, false, false, child);

                child.classList.add('none');

                item.classList[window.hasClass(item, 'active') ? 'remove' : 'add']('active');
                parent.after(child);

                child.classList.remove('none');
                child.style.display = 'block';
                window.scrollTo({
                    top: child.offsetTop, behavior: 'smooth',
                });
            }, false, true);
        });
    }, true);

    window.searchAndClosureSelector('.comments-form *[type="submit"]', (item) => {
        item.addEventListener('click', window.commentsFormCheck, true);
    }, true);

    window.searchAndClosureSelector('.item-buttons__helpful', (item) => {
        item.addEventListener('click', () => {
            window.sendRequest({
                action: 'comments_set_helpful', nonce: hex_nonce, id: item.getAttribute('data-comments-id'),
            }, 'POST', hexjs.ajax, {
                Accept: 'application/json', 'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
            }, (xhr) => {
                if (parseInt(xhr.response) > 0) {
                    window.searchAndClosureSelector('span', (child) => {
                        child.textContent = parseInt(child.textContent) + 1;
                    }, false, false, item);
                }
            }, () => {
                item.setAttribute('disabled', true);
            });
        });
    }, true);

    window.searchAndClosureSelector('.comments-list__more', (item) => {
        item.addEventListener('click', () => {
            window.searchAndClosureSelector('.comments-list__item.none', (child) => {
                child.classList.remove('none');
                child.style.display = 'block';
            }, true, false, window.prevSibling(item, '.comments-list__items'));
            item.remove();
        });
    }, true);

    window.searchAndClosureSelector('.form-input .input', (item) => {
        item.addEventListener('change', () => {
            item.classList.remove('error');
            let next = window.nextSibling(item, '.error');
            if (next) {
                next.innerHTML = '';
            }
        });
    }, true);

    window.searchAndClosureSelector('commentsCancel', (item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            window.searchAndClosureSelector('commentsForm', (child) => {
                child.classList.add('none');
                child.style.display = 'none';
            }, false, true);
        });
    }, false, true);
});
