window.addEventListener('DOMContentLoaded', () => {
    let viewport = window.updateViewportDimensions();
    window.getRecaptchaV3();

    if (hexjs.geo_info) {
        let country_code = hexjs.geo_info.countryCode ? hexjs.geo_info.countryCode.toLowerCase() : false,
            country_region = hexjs.geo_info.countryRegion ? hexjs.geo_info.countryRegion.toLowerCase() : false,
            geo_hide_country = '.hide-' + (country_code ? country_code : ''),
            geo_hide_region = country_region ? geo_hide_country + '-' + country_region : false;

        if (geo_hide_country) {
            window.searchAndClosureSelector(geo_hide_country, (item) => {
                item.style.display = 'none';
            }, true);
        }

        if (geo_hide_region) {
            window.searchAndClosureSelector(geo_hide_region, (item) => {
                item.style.display = 'none';
            }, true);
        }
    }

    document.addEventListener('click', function (event) {
        if (event.target.closest('.searchBtn.opened') === null && event.target.closest('#searchInput') === null) {
            let openSearch = document.querySelector('.searchBtn.opened');
            if (openSearch) {
                openSearch.click();
            }
        }
    });

    document.addEventListener('keyup', (e) => {
        if (e.keyCode === 27) {
            window.searchAndClosureSelector('.popup-close', (item) => {
                item.click();
            }, true);
            window.searchAndClosureSelector('.loop-filters .field', (item) => {
                item.classList.remove('open');
            }, true);
        }
    });

    window.searchAndClosureSelector('.avial', (item) => {
        item.addEventListener('click', () => {
            window.doAvial(item.getAttribute('data-pid'), 0);
            return false;
        });
    }, true);

    setTimeout(() => {
        window.searchAndClosureSelector('cookieConsent', () => {
            window.searchAndClosureSelector('topcontrol', (child) => {
                child.classList.add('hasCookie');
            }, false, true);
            document.body.classList.add('hasCookie');
        }, false, true);
    }, 100);

    window.searchAndClosureSelector('#cookieConsent .btn', (item) => {
        item.addEventListener('click', () => {
            window.setCookie('CookieConsent', 1, 30);
            window.searchAndClosureSelector('cookieConsent', (child) => {
                child.classList.add('hidden');
                window.awaitClosure(500, () => {
                    child.remove();
                });
            }, false, true);

            window.searchAndClosureSelector('topcontrol', (child) => {
                child.classList.add('hasCookie');
            }, false, true);
            document.body.classList.add('hasCookie');
        });
    }, true);

    window.searchAndClosureSelector('#ageContainer .btn', (item) => {
        item.addEventListener('click', () => {
            let parent = item.closest("#ageContainer"),
                oldest = 0;

            if (window.hasClass(parent, 'locked')) {
                oldest = window.hasClass(item, 'not-allow') ? 1 : 2;

                if (oldest === 1) {
                    document.body.classList.add('no-scroll');
                    window.searchAndClosureSelector(':scope > .popup-content', (child) => {
                        child.classList[window.hasClass(child, 'not-allow') ? "remove" : "add"]('none');
                    }, true, false, parent);
                }
            } else {
                oldest = 2;
            }
            console.log(oldest);
            window.setCookie('AgeConsent', oldest, 30);
            if (oldest === 2) {
                parent.classList.add('hidden');
                window.awaitClosure(500, () => {
                    parent.remove();
                });
            }
        });
    }, true);

    if (!window.hasClass(document.body, 'deavial') && window.hasClass(document.getElementById('avialContainer'), 'error')) {
        window.doAvial();
    }

    if (window.location.href.indexOf('goto=false') !== -1) {
        window.doAvial();
    }

    if (viewport.width > 1200) {
        window.searchAndClosureSelector('.animated-items', (item) => {
            let cls = item.getAttribute('data-animation'), inc = 0;

            window.searchAndClosureSelector(':scope > *:not(:first-child)', (child) => {
                inc++;
                child.classList.add(cls);
                child.style.transitionDelay = inc * 0.1 + 's';
                child.style.animationDelay = inc * 0.1 + 's';
            }, true, false, item);

            if (hasClass(item, 'numbers')) {
                window.searchAndClosureSelector(':scope > * > b', (child) => {
                    child.setAttribute('data-text', child.textContent);
                    child.textContent = 0;
                }, true, false, item);
            }
        }, true);

        document.addEventListener('scroll', () => {
            let lastKnownScrollPosition = window.scrollY;

            window.searchAndClosureSelector('.animated-items', (item) => {
                let aiTop = item.getBoundingClientRect().top - 0.7 * window.innerHeight;
                if (lastKnownScrollPosition > aiTop && !hasClass(item, 'is-animated')) {
                    item.classList.add('is-animated');
                    if (hasClass(item, 'numbers')) {
                        window.searchAndClosureSelector(':scope > * > b', (child) => {
                            let cnt = parseInt(child.getAttribute('data-text')),
                                inc = cnt >= 10000 ? 100 : cnt >= 1000 ? 10 : 1, tvl = 0, lst = cnt % inc;

                            cnt -= lst;
                            setInterval(function () {
                                if ((cnt -= inc) >= 0) {
                                    child.textContent = tvl += inc;
                                    if (cnt === 0) {
                                        child.textContent = tvl += lst;
                                        if (child.getAttribute('data-text').indexOf('+') > -1) {
                                            child.textContent = tvl + '+';
                                        }
                                    }
                                }
                            }, 10);
                        }, true, false, item);
                    }
                }
            }, true);
        });
    }

    if (window.location.hash) {
        window.searchAndClosureSelector('.tg:not(.opened) .tg-btn', (item) => {
            window.tgOpen(item);
        }, true);

        let target = window.location.hash.substring(1);
        window.scrollToTarget(target);
    }

    window.searchAndClosureSelector('a[href^="#"]', (item) => {
        let target = item.getAttribute("href").substring(1);
        if (target) {
            item.addEventListener('click', () => {
                window.scrollToTarget(target);
            });
        }
    }, true);

    window.searchAndClosureSelector('.toggle-btn', (item) => {
        item.addEventListener('click', () => {
            let parent = item.closest(".toggle");

            for (let i = 3; i <= 6; i++) {
                window.searchAndClosureSelector('li.toc-' + i, (child) => {
                    child.classList[window.hasClass(child, 'open') ? 'remove' : 'add']('open');
                }, true, false, parent);
            }
            item.classList[window.hasClass(item, 'open') ? 'remove' : 'add']('open');
        });
    }, true);

    window.searchAndClosureSelector('.jumpli a', (item) => {
        item.addEventListener('click', () => {
            window.searchAndClosureSelector('.tg:not(.opened) .tg-btn', (item) => {
                window.tgOpen(item);
            }, true);
        });
    }, true);

    window.searchAndClosureSelector('.tg-btn', (item) => {
        item.addEventListener('click', () => {
            window.tgOpen(item);
        });
    }, true);

    window.searchAndClosureSelector('.tc-item a', (item) => {
        item.setAttribute('target', '_blank');
    }, true);

    window.searchAndClosureSelector('#header ul li.menu-item-has-children > a', (item) => {
        item.addEventListener('click', (e) => {
            if (e.target.tagName === "I") {
                e.preventDefault();
                e.stopPropagation();
            }
            let parent = item.closest("li");
            parent.classList[window.hasClass(parent, 'open') ? 'remove' : 'add']('open');
        });
    }, true);

    window.searchAndClosureSelector('#linkerMenu .current', (item) => {
        item.addEventListener('click', () => {
            item.classList[window.hasClass(item, 'opened') ? 'remove' : 'add']('opened');
        });
    });

    window.searchAndClosureSelector('searchInput', (item) => {
        let searchList = document.querySelector("#searchContainer ul.searchList");
        item.addEventListener('input', (e) => {
            let search_text = e.target.value;
            searchList.classList.remove("filled");
            searchList.innerHTML = "";
            item.classList.add("working");

            (async () => {
                if (search_text) {
                    let data = new FormData();
                    data.append('action', "search");
                    data.append('nonce', hex_nonce);
                    data.append('term', encodeURIComponent(search_text));

                    const rawResponse = await fetch(hexjs.ajax, {
                        method: 'POST',
                        headers: {
                            'X-WP-Nonce': hex_nonce
                        }, body: data
                    });
                    const object = await rawResponse.json();

                    if (object.length > 0) {
                        searchList.innerHTML = "";
                        object.forEach((element) => {
                            let li = document.createElement("li");

                            if (element.image !== "" && element.image !== "/wp-content/themes/hex3/app/images/nothumb.webp") {
                                let img = document.createElement("img");
                                img.setAttribute("src", element.image);
                                img.setAttribute("title", element.value);
                                li.appendChild(img);
                            }

                            if (element.link) {
                                let link = document.createElement("a");
                                link.setAttribute("href", element.link);
                                link.textContent = element.value;
                                li.appendChild(link);
                            } else {
                                let span = document.createElement("span");
                                span.textContent = element.value;
                                li.appendChild(span);
                            }

                            searchList.appendChild(li);
                        });
                        searchList.classList.add("filled");
                    }
                }

                item.classList.remove("working");
                item.focus();
            })();
        });
    }, false, true);

    // window.searchAndClosureSelector(".scroll", (item) => {
    //     item.addEventListener("click", (event) => {
    //         event.preventDefault();
    //
    //         var target = $(this).attr('data-tab');
    //         var target_offset = $("#" + target).offset();
    //         var target_top = target_offset.top - $('.header').height();
    //
    //         window.location.hash = target;
    //
    //         $('html, body').animate({scrollTop: target_top}, 500);
    //     });
    // }, true);

    window.searchAndClosureSelector('#issueContainer form .step input[name="email"]', (item) => {
        item.addEventListener('input', (e) => {
            let value = e.target.value;
            window.issueReportVerifyMail(value);
        });
    });

    window.searchAndClosureSelector('issueReportBtn', (item) => {
        item.addEventListener('click', () => {
            let step = parseInt(item.getAttribute('data-step')), next_step = step + 1;

            item.classList.add('btn-grey');
            item.classList.remove('btn-green');
            item.setAttribute('disabled', true);

            switch (step) {
                case 1:
                    window.searchAndClosureSelector('#issueContainer .popup-close', (child) => {
                        child.setAttribute('data-id', '');
                    }, true);
                    break;
                case 2:
                    let data = {};

                    window.searchAndClosureSelector('#issueContainer form', (form) => {
                        data = new FormData(form);
                    });

                    if (window.issueReportVerifyMail(data.get("email"))) {
                        data.append('action', "shb_api_feedback_set");

                        let data_value = item.getAttribute('data-value');
                        item.setAttribute('data-value', item.value);
                        item.value = data_value;
                        item.setAttribute('data-step', next_step);

                        next_step = step + 1;

                        (async () => {
                            const rawResponse = await fetch(hexjs.ajax, {
                                method: 'POST', headers: {
                                    // 'Accept': 'application/json',
                                    // 'Content-type': 'application/json; charset=UTF-8',
                                    'X-WP-Nonce': hex_nonce
                                }, body: data
                            });
                            await rawResponse.json();
                        })();

                        window.searchAndClosureSelector('#issueContainer .popup-close', (child) => {
                            child.setAttribute('data-id', 'issue');
                        }, true);
                    } else {
                        next_step = step;
                    }
                    break;
                case 3:
                    window.searchAndClosureSelector('#issueContainer form', (form) => {
                        form.reset();
                    });

                    window.searchAndClosureSelector('#issueContainer .popup-close', (child) => {
                        child.click();
                    }, true);

                    window.searchAndClosureSelector('#issueContainer .step', (child) => {
                        if (child.classList.contains("step-1")) {
                            child.classList.remove('none');
                        } else {
                            child.classList.add('none');
                        }
                    }, true);

                    let data_value = item.getAttribute('data-value');
                    item.setAttribute('data-value', item.value);
                    item.value = data_value;
                    item.setAttribute('data-step', 1);
                    return false;
            }

            if (step > 1 && step < 3) {
                item.classList.add('btn-green');
                item.classList.remove('btn-grey');
                item.removeAttribute('disabled');
            }

            item.setAttribute('data-step', next_step);

            window.searchAndClosureSelector('#issueContainer .step', (child) => {
                child.classList.add('none');
            }, true);
            window.searchAndClosureSelector('#issueContainer .step-' + next_step, (child) => {
                child.classList.remove('none');
            }, true);

            item.setAttribute('data-step', next_step);
            return false;
        });
    }, false, true);

    window.searchAndClosureSelector('ul.hwr-criteria li label', (item) => {
        item.addEventListener('click', () => {
            let cnt = item.closest('li');
            cnt.classList[window.hasClass(cnt, 'open') ? 'remove' : 'add']('open');
        });
    }, true);

    window.searchAndClosureSelector('.hwr-tpl .hwr-btn', (item) => {
        item.addEventListener('click', () => {
            let inc = 0, tx = item.textContent;

            item.textContent = item.getAttribute('data');
            item.setAttribute('data', tx);

            window.searchAndClosureSelector('ul.hwr-criteria', (child) => {
                child.classList.add('is-animated');
            }, true);

            window.searchAndClosureSelector('ul.hwr-criteria li', (child) => {
                inc += 1;
                child.classList.add('fadeInDown');
                child.style.transitionDelay = inc * 0.1 + 's';
                child.style.animationDelay = inc * 0.1 + 's';
                child.classList[window.hasClass(child, 'd-block') ? 'remove' : 'add']('d-block');
            }, true);
        });
    }, true);

    window.searchAndClosureSelector('.video-box.youtube .video-box-btn', (item) => {
        item.addEventListener('click', () => {
            item.style.display = 'none';

            let cont = item.parentElement,
                frame = document.createElement("iframe"),
                img = cont.querySelector('img:first-child');

            frame.setAttribute("src", "https://www.youtube.com/embed/" + item.getAttribute('data-src') +  "?autoplay=1");
            frame.setAttribute("width", img.offsetWidth);
            frame.setAttribute("height", img.offsetHeight);
            frame.setAttribute("allow", "autoplay");
            frame.setAttribute("frameborder", "0");
            frame.setAttribute("allowfullscreen", "allowfullscreen");

            cont.insertBefore(frame, img);
            img.remove();
        });
    }, true);

    window.searchAndClosureSelector('.guides-items .btn', (item) => {
        item.addEventListener('click', () => {
            window.searchAndClosureSelector('.fa-spinner', (child) => {
                child.classList.add('fa-spin');
            }, false, false, item);

            setTimeout(function () {
                let parent = item.closest('.guides-items');

                window.searchAndClosureSelector('.hide', (child) => {
                    child.classList[window.hasClass(child, 'none') ? 'remove' : 'add']('none');
                    child.style.display = window.hasClass(child, 'none') ? 'none' : 'block';
                }, true, false, parent);

                window.searchAndClosureSelector('.fa-spinner', (child) => {
                    child.classList.remove('fa-spin');
                }, false, false, item);

                let text = item.innerHTML;
                item.innerHTML = item.getAttribute('data-text');
                item.setAttribute('data-text', text);
            }, 500);
        });
    }, true);

    window.searchAndClosureSelector('.guides-items-nav a', (item) => {
        item.addEventListener('click', () => {
            let parent = item.closest('ul');

            window.searchAndClosureSelector('.active', (child) => {
                child.classList.remove('active');
            }, true, false, parent);
            item.classList.add('active');

            let id = item.getAttribute('href');

            window.searchAndClosureSelector('.guides-items', (child) => {
                if (id === '#guides-items-nav') {
                    child.classList.remove('none');
                    child.classList.remove('active');
                } else {
                    child.classList.add('none');
                    window.searchAndClosureSelector(id, () => {
                        child.classList.remove('none');
                        child.classList.add('active');
                    });
                }
            }, true);
        });
    }, true);
});