window.displaySelectedFilter = async function (loop, filter, button) {
    await new Promise(() => {
        let loopItems = loop.querySelectorAll(".item"),
            active = filter.querySelector(".filters-active");

        loopItems.forEach((item) => {
            item.removeAttribute("style");
        });

        window.searchAndClosureSelector('ul[data-key]', (ul) => {
            let is_open = false,
                field = filter.querySelector(".field[data-field='" + ul.getAttribute("data-field") + "']");

            window.searchAndClosureSelector('li:not(.field-label)', (child) => {
                child.remove();
            }, true, false, ul);

            window.searchAndClosureSelector('ul li input:checked', (child) => {
                let li = document.createElement("li");
                li.textContent = window.nextSibling(child, 'span').textContent;

                li.addEventListener('click', () => {
                    child.removeAttribute('checked', false);
                    button.click();
                });

                ul.appendChild(li);
                is_open = true;
            }, true, false, field);

            if (window.hasClass(field, 'field-range') || window.hasClass(field, 'field-mix')) {
                let ff = field.querySelector('.field-from'),
                    ft = field.querySelector('.field-to'),
                    fft = '';

                if (ff.value) {
                    fft += ff.getAttribute('placeholder').toLowerCase() + ' ' + ff.value + ' ';
                }
                if (ft.value) {
                    fft += ft.getAttribute('placeholder').toLowerCase() + ' ' + ft.value + ' ';
                }

                if (fft.length) {
                    let li = document.createElement("li");
                    li.textContent = fft;

                    li.addEventListener('click', () => {
                        ff.value = '';
                        ft.value = '';
                        button.click();
                    });
                    ul.appendChild(li);
                    is_open = true;
                }
            }

            if (is_open) {
                field.classList.add('active');
                ul.classList.add('open');
                active.classList.add('open');
            } else {
                field.classList.remove('active');
                ul.classList.remove('open');

                if (!active.querySelectorAll('ul.open').length) {
                    active.classList.remove('open');
                }
            }

            field.classList.remove('open');
            loop.classList.add('load');
        }, true, false, filter);
    });
}

window.clickDisableSelected = function (filter, button) {
    window.searchAndClosureSelector('.filters-active > ul li:not(.field-label)', (li) => {
        let ul = li.parentNode;
        li.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            let fieldKey = ul.getAttribute("data-key"),
                fieldValue = li.textContent,
                searchIn = document.querySelector(".filters-fields .field[data-key='" + fieldKey + "']"),
                xpath = "//span[text()='" + fieldValue + "']",
                matchingElement = document.evaluate(xpath, searchIn, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

            if (matchingElement) {
                let parent = matchingElement.closest("label"),
                    checkbox = parent.querySelector(":scope > input:checked");

                if (checkbox) {
                    checkbox.checked = false;
                    button.click();
                }
            }
        });
    }, true, false, filter);
}

window.addEventListener('DOMContentLoaded', () => {
    window.searchAndClosureSelector('.loop-filters .field .btn-submit', (item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            let loop = item.closest('.loop-container'),
                filter = item.closest('.loop-filters');

            window.displaySelectedFilter(loop, filter, item);

            let fields = [],
                infoData = new FormData(),
                form = document.getElementById('fm_' + filter.getAttribute('id')), formData = new FormData(form),
                query = JSON.parse(document.getElementById('dt_' + filter.getAttribute('id')).textContent), data = [];

            window.searchAndClosureSelector('.field', (child) => {
                fields.push(child.getAttribute('data-key'));
            }, true, false, filter);

            for (let pair of formData.entries()) {
                data.push({
                    "name": pair[0], "value": pair[1]
                });
            }

            infoData.append('action', "loop_filters");
            infoData.append('nonce', hex_nonce);
            infoData.append('formdata', JSON.stringify(data));
            infoData.append('query', JSON.stringify(query));
            infoData.append('fields', JSON.stringify(fields));

            (async () => {
                const rawResponse = await fetch(hexjs.ajax, {
                    method: 'POST', headers: {
                        // 'Accept': 'application/json',
                        // 'Content-Type': 'application/json',
                        'X-WP-Nonce': hex_nonce
                    }, body: infoData
                });
                const object = await rawResponse.json();

                if (object.loop.length > 0) {
                    let element = window.createElementFromHTML(object.loop);

                    window.hasClass(element, 'is-filtered') ? loop.classList.add('is-filtered') : loop.classList.remove('is-filtered');

                    window.searchAndClosureSelector(':scope > *:not(.loop-filters)', (child) => {
                        child.remove();
                    }, true, false, loop);

                    while (element.childNodes.length > 0) {
                        loop.appendChild(element.childNodes[0]);
                    }

                    window.searchAndClosureSelector(':scope .tocasino', (child) => {
                        child.addEventListener('click', () => {
                            window.clickToCasino(child);
                            return false;
                        });
                    }, true, false, loop);

                    window.searchAndClosureSelector(':scope .avial', (child) => {
                        child.addEventListener('click', () => {
                            window.doAvial(child.getAttribute('data-pid'), 0);
                            return false;
                        });
                    }, true, false, loop);

                    window.searchAndClosureSelector('.filters-show i', (child) => {
                        child.textContent = parseInt(element.getAttribute('data-count'));
                    }, true, false, filter);
                }

                if (object.fields) {
                    for (const [key, value] of Object.entries(object.fields)) {
                        window.searchAndClosureSelector('.field[data-key="' + key + '"]', (child) => {
                            window.searchAndClosureSelector('input[type="checkbox"] ~ i ', (elem) => {
                                elem.textContent = 0;
                            }, true, false, child);

                            value.forEach((elem) => {
                                window.searchAndClosureSelector('input[type="checkbox"][value="' + elem.value + '"] ~ i', (el) => {
                                    el.textContent = elem.count;
                                }, true, false, child);
                            });
                        }, false, false, filter);
                    }
                }

                window.clickDisableSelected(filter, item);

                setTimeout(function () {
                    loop.classList.remove('load');
                }, 1000);
            })();

            /* events */
            if (typeof dataLayer != 'undefined' && dataLayer != null) {
                window.searchAndClosureSelector('.filters-active > ul.open', (ul) => {
                    dataLayer.push({
                        event: 'GAevent',
                        eventCategory: 'Other',
                        eventAction: 'Filter',
                        eventLabel: ul.getAttribute('data-field'),
                    });
                }, true, false, filter);
            }

            return false;
        });
    }, true);

    window.searchAndClosureSelector('.loop-filters .field .btn-cancel', (item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            let loopFilters = item.closest(".loop-filters");

            window.searchAndClosureSelector('ul li input:checked', (el) => {
                el.checked = false;
            }, true, false, loopFilters);

            window.searchAndClosureSelector('input[type="text"]', (el) => {
                el.value = '';
            }, true, false, loopFilters);

            prevSibling(item, '.btn-submit').click();
        });
    }, true);

    window.searchAndClosureSelector('.loop-filters .field .field-label', (item) => {
        item.addEventListener('click', () => {
            let parent = item.parentElement;
            window.searchAndClosureSelector('.filters-fields .field.open', (child) => {
                if (parent.getAttribute("data-key") !== child.getAttribute("data-key")) {
                    child.classList.remove('open');
                }
            }, true);
            parent.classList[window.hasClass(parent, 'open') ? 'remove' : 'add']('open');
        });
    }, true);

    window.searchAndClosureSelector('.loop-filters .field .field-search', (item) => {
        item.addEventListener('keyup', () => {
            let inp = item.value.toLowerCase();

            window.searchAndClosureSelector('li', (child) => {
                child.classList[child.textContent.toLowerCase().indexOf(inp) !== -1 ? 'remove' : 'add']('none');
            }, true, false, nextSibling(item, 'ul'));
        });
    }, true);

    window.searchAndClosureSelector('.loop-filters .field-more', (item) => {
        item.addEventListener('click', () => {
            let tx = item.textContent,
                has_hidden = false;

            window.searchAndClosureSelector('.filters-fields .field.none', (child) => {
                child.classList.remove('none');
                child.classList.add('be-hidden');
                has_hidden = true;
            }, true);

            item.textContent = item.getAttribute('data-text');
            item.setAttribute('data-text', tx);

            if (!has_hidden) {
                window.searchAndClosureSelector('.filters-fields .field.be-hidden', (child) => {
                    child.classList.add('none');
                    child.classList.remove('be-hidden');
                }, true);
            }
        });
    }, true);

    window.searchAndClosureSelector('.loop-filters .filters-fields-trigger', (item) => {
        item.addEventListener('click', () => {
            let parent = item.closest('.loop-filters');
            parent.classList[window.hasClass(parent, 'open') ? 'remove' : 'add']('open');
            document.body.classList[window.hasClass(document.body, 'no-scroll') ? 'remove' : 'add']('no-scroll');
        });
    }, true);

    window.searchAndClosureSelector('.loop-filters .filters-show', (item) => {
        item.addEventListener('click', () => {
            let parent = item.closest('.loop-filters');
            window.searchAndClosureSelector('.filters-fields-trigger', (child) => {
                child.click();
            }, true, false, parent);
        });
    }, true);

    window.searchAndClosureSelector('.loop-filters .filters-close', (item) => {
        item.addEventListener('click', () => {
            let parent = item.closest('.loop-filters');
            window.searchAndClosureSelector('.filters-fields-trigger', (child) => {
                child.click();
            }, true, false, parent);
        });
    }, true);

    window.searchAndClosureSelector('.loop-filters .filters-cancel', (item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            let parent = item.closest('.loop-filters');
            window.searchAndClosureSelector('fs_' + parent.getAttribute('id'), (child) => {
                child.value = '';
            }, true, true);

            window.searchAndClosureSelector('input[name="p__0"]', (child) => {
                child.value = '';
            }, true, false, parent);

            window.searchAndClosureSelector('.filters-fields .btn-cancel', (child) => {
                child.click();
            }, true, false, parent);
        });
    }, true);

    window.searchAndClosureSelector('.loop-filters', (item) => {
        let search = null, filtersActive = item.querySelector(".filters-active");
        window.searchAndClosureSelector('.filters-search input', (child) => {
            search = child;
        }, false, false, item);

        if (search) {
            let query = JSON.parse(document.getElementById("dt_" + item.getAttribute('id')).textContent),
                parent = search.parentElement, loopContainer = search.closest(".loop-container"),
                list = parent.querySelector("ul");

            ["input", "paste"].forEach((event) => {
                search.addEventListener(event, (e) => {
                    let text = e.target.value;

                    if (text.length === 0) {
                        let loopItems = loopContainer.querySelectorAll(".item");
                        filtersActive.classList.remove("open");
                        loopItems.forEach((item) => {
                            item.removeAttribute("style");
                        });
                        return;
                    }
                    search.classList.add("disabled");
                    search.setAttribute("disabled", "disabled");

                    if (list) {
                        list.innerHTML = "";
                    }

                    let infoData = new FormData();
                    infoData.append('action', "filters_search");
                    infoData.append('nonce', hex_nonce);
                    infoData.append('term', text);
                    infoData.append('query', JSON.stringify(query));

                    (async () => {
                        const rawResponse = await fetch(hexjs.ajax, {
                            method: 'POST', headers: {
                                'X-WP-Nonce': hex_nonce
                            }, body: infoData
                        });
                        const object = await rawResponse.json();
                        search.classList.add("searched");
                        list.style.display = 'block';
                        if (object.length) {
                            object.forEach((element) => {
                                let li = document.createElement("li");
                                li.setAttribute("value", element.value);
                                li.textContent = element.label;

                                li.addEventListener('click', () => {
                                    let searchTitle = li.textContent, xpath = "//span[text()='" + searchTitle + "']",
                                        matchingElement = document.evaluate(xpath, loopContainer, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

                                    if (matchingElement) {
                                        let searched = matchingElement.closest(".item");
                                        searched.classList.add("searched");
                                        let loopItems = loopContainer.querySelectorAll(".item:not(.searched)");

                                        filtersActive.classList.add("open");
                                        loopItems.forEach((item) => {
                                            item.style.display = "none";
                                        });
                                    }
                                    search.classList.remove("searched");
                                });
                                list.appendChild(li);
                            });
                            parent.appendChild(list);
                        }

                        search.classList.remove("disabled");
                        search.removeAttribute("disabled");
                        search.focus();
                    })();

                    // window.sendRequest({
                    //         query: query,
                    //         term: text,
                    //         action: 'filters_search',
                    //         nonce: hex_nonce,
                    //     },
                    //     'POST',
                    //     hexjs.ajax, {
                    //         'Accept': 'application/json',
                    //         'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    //     }, (xhr) => {
                    //     search.classList.add("searched");
                    //     list.style.display = 'block';
                    //     let object = JSON.parse(xhr.response);
                    //     if (object.length) {
                    //         object.forEach((element) => {
                    //             let li = document.createElement("li");
                    //             li.setAttribute("value", element.value);
                    //             li.textContent = element.label;
                    //
                    //             li.addEventListener('click', () => {
                    //                 let searchTitle = li.textContent, xpath = "//span[text()='" + searchTitle + "']",
                    //                     matchingElement = document.evaluate(xpath, loopContainer, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
                    //
                    //                 if (matchingElement) {
                    //                     let searched = matchingElement.closest(".item");
                    //                     searched.classList.add("searched");
                    //                     let loopItems = loopContainer.querySelectorAll(".item:not(.searched)");
                    //
                    //                     filtersActive.classList.add("open");
                    //                     loopItems.forEach((item) => {
                    //                         item.style.display = "none";
                    //                     });
                    //                 }
                    //                 search.classList.remove("searched");
                    //             });
                    //             list.appendChild(li);
                    //         });
                    //         parent.appendChild(list);
                    //     }
                    // }, () => {
                    //     search.classList.remove("disabled");
                    //     search.removeAttribute("disabled");
                    //     search.focus();
                    // });
                });
            });

            document.addEventListener('click', () => {
                search.classList.remove("searched");
                list.style.display = 'none';
            });
        }
    }, true);

    window.searchAndClosureSelector('.select-custom', (item) => {
        let numberOfOptions = document.querySelectorAll('.select-custom option').length,
            firstOption = document.querySelector('.select-custom option[value="' + item.value + '"]');

        item.classList.add('hide');

        let styledSelect = document.createElement('div'), wrapItem = document.createElement('div'),
            list = document.createElement('ul');

        styledSelect.classList.add("select-styled");
        wrapItem.classList.add("select-box");
        list.classList.add("options");
        window.wrap(item, wrapItem);
        wrapItem.appendChild(styledSelect);

        // Show the first select option in the styled div
        styledSelect.textContent = firstOption.textContent;

        // Insert a list item into the unordered list for each select option
        for (let i = 0; i < numberOfOptions; i++) {
            let li = document.createElement('li');

            li.setAttribute("rel", item[i].value);
            li.textContent = item[i].textContent;

            li.addEventListener('click', () => {
                styledSelect.textContent = li.textContent;
                styledSelect.classList.remove('active');

                item.value = li.getAttribute('rel');
                list.style.display = 'none';

                let parent = item.closest('.loop-filters');
                window.searchAndClosureSelector('.filters-fields .btn-submit', (child) => {
                    child.click();
                }, false, false, parent);
            });

            list.appendChild(li);
        }
        wrapItem.appendChild(list);

        styledSelect.addEventListener('click', (e) => {
            e.stopPropagation();
            window.searchAndClosureSelector('div.select-styled.active', (child) => {
                child.classList.remove('active');
                nextSibling(child, 'ul.options').style.display = 'none';
            }, false, false, item);
            styledSelect.classList[window.hasClass(styledSelect, 'active') ? 'remove' : 'add']('active');
            toggleElement(list);
        });

        // Hides the unordered list when clicking outside of it
        document.addEventListener('click', () => {
            styledSelect.classList.remove('active');
            list.style.display = 'none';
        });
    }, true);
});
