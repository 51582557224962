window.addEventListener('DOMContentLoaded', () => {
    searchAndClosureSelector('.load-more-container.bonuses .btn', (item) => {
        item.addEventListener('click', () => {
            let item_container = item.parentNode.previousElementSibling, icon = item.querySelector('i'),
                per_page = parseInt(item.getAttribute('data-perpage'));
            if (item_container) {
                let items = item_container.querySelectorAll('.item.none');

                icon.classList.add('fa-spin');

                setTimeout(function () {
                    icon.classList.remove('fa-spin');
                    items.forEach((item) => {
                        if (per_page > 0) {
                            let next_item = item.nextElementSibling;
                            item.classList.remove('none');

                            if (hasClass(next_item, 'tc-item')) {
                                next_item.classList.remove('none');
                            }
                        }
                        per_page--;
                    });
                    if (per_page > 0) {
                        item.classList.add('none');
                    }
                }, 1000);
            }
        });
    }, true);

    searchAndClosureSelector('.load-more-container.casinos .btn', (item) => {
        item.addEventListener('click', () => {
            let icon = item.querySelector('i'),
                args = JSON.parse(item.getAttribute('data-args')),
                table = document.getElementById('loop_caches_' + args[1] + '_' + item.getAttribute('data-id')),
                loop_container = table.closest(".loop-container.casinos"),
                loop_max_items = parseInt(loop_container.getAttribute("data-count")),
                data = new FormData();

            icon.classList.add('fa-spin');

            data.append('action', "loadmore_casinos");
            data.append('nonce', hex_nonce);
            data.append('args', JSON.stringify(args));

            (async () => {
                const rawResponse = await fetch(hexjs.ajax, {
                    method: 'POST', headers: {
                        'X-WP-Nonce': hex_nonce
                    }, body: data
                });
                const object = await rawResponse.json();

                if (Object.keys(object).length > 0) {
                    args[3] += 1;

                    for (const key in object) {
                        let element = window.createElementFromHTML(object[key].replace(/[\r\n\t]/g, ""), "tbody");

                        window.searchAndClosureSelector('.tocasino', (button) => {
                            button.addEventListener('click', () => {
                                window.clickToCasino(button);
                            });
                        }, true, false, element);

                        window.searchAndClosureSelector('.avial', (button) => {
                            button.addEventListener('click', () => {
                                window.doAvial(button.getAttribute('data-pid'), 0);
                            });
                        }, true, false, element);

                        table.appendChild(element);
                    }
                    item.setAttribute('data-args', JSON.stringify(args));

                    console.log(Object.keys(object).length < args[2]["posts_per_page"]);
                    console.log(loop_max_items);
                    console.log(table.querySelectorAll(":scope > .item").length);

                    if (Object.keys(object).length < args[2]["posts_per_page"]
                        || loop_max_items <= table.querySelectorAll(":scope > .item").length) {
                        item.remove();
                    }
                }

                icon.classList.remove('fa-spin');
            })();
        });
    }, true);

    searchAndClosureSelector('.btn-load-more', (item) => {
        item.addEventListener('click', () => {
            let step = parseInt(item.getAttribute('data-step')),
                perpage = parseInt(item.getAttribute('data-perpage')),
                pt = item.getAttribute('data-pt'),
                loop_type = item.getAttribute('data-type'),
                loop_nonce = item.getAttribute('data-nonce'),
                loop_args = item.getAttribute('data-args'),
                loop_container = document.querySelector('.loop-container.' + loop_type),
                loop_max_items = loop_container.getAttribute("data-count");

            if (hasClass(item, 'btn')) {
                searchAndClosureSelector('i', (child) => {
                    child.classList.add('fa-spin');
                }, false, false, item);
            } else {
                item.setAttribute('data-step', 1);
                item.style.display = 'none';
            }

            if (step === 0) {
                loop_container.innerHTML = '';
            }

            if (pt.length > 0) {
                item.setAttribute('data-pt', pt);
            }

            sendRequest({
                action: 'loadmore',
                type: loop_type,
                secure: loop_nonce,
                args: loop_args,
                offset: step * perpage,
                pt: pt,
            }, 'POST', hexjs.home + '/wp-content/themes/hex3/includes/queries.php', {
                'Accept': 'application/json',
                'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
            }, (xhr) => {
                if (xhr.response.length > 0) {
                    loop_container.innerHTML += xhr.response;
                    if (hasClass(item, 'btn')) {
                        item.setAttribute('data-step', step + 1);
                    } else {
                        item.style.display = 'inline-block';
                    }
                    if (xhr.response.match(/class="item"/gi).length < perpage
                        || loop_max_items <= (step + 1) * perpage) {
                        item.style.display = 'none';
                    }
                } else {
                    item.style.display = 'none';
                }

                searchAndClosureSelector('i', (child) => {
                    child.classList.remove('fa-spin');
                }, false, false, item);
            });
        });
    }, true);
});
