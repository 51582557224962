// import $ from 'zepto-modules';
import Swiper from 'swiper';
import './components/loadPage';
import './components/functions';
import './components/other';
import './components/clickToCasino';
import './components/comments';
import './components/loadMore';
import './components/popup';
import './components/reveal';
import './components/filters';

// search
const searchBox = document.querySelector('#search');
const searchBoxButton = document.querySelector('#search button');
const headerLimitation = document.querySelector('.headerLimitation');
if (searchBox) {
  searchBoxButton.addEventListener('click', () => {
    searchBox.classList.toggle('open');
    if (searchBox.classList.contains('open')) {
      headerLimitation.classList.add('hide');
    } else {
      function removeHide() {
        headerLimitation.classList.remove('hide');
      }

      setTimeout(removeHide, 200);
    }
  });
}

const userButton = document.querySelector('.headerUser button');
const userButtonClose = document.querySelector('.headerUser .headerUserClose');
if (userButton) {
  userButton.addEventListener('click', () => {
    userButton.closest('.headerUser').classList.toggle('open');
  });
  userButtonClose.addEventListener('click', () => {
    userButton.closest('.headerUser').classList.remove('open');
  });
}

// burger
const burger = document.querySelector('.burger');
const offcanvas = document.querySelector('.off-canvas');
if (burger) {
  burger.addEventListener('click', () => {
    burger.classList.toggle('opened');
    offcanvas.classList.toggle('opened');
  });
}

// mobile Menu
const headerMenuMobileButtons = document.querySelectorAll(
  '.headerMenuMobileButton'
);
if (headerMenuMobileButtons.length > 0) {
  for (const mobButton of headerMenuMobileButtons) {
    mobButton.addEventListener('click', (e) => {
      // e.stopPropagation();
      e.preventDefault();
      mobButton.classList.toggle('open');
      mobButton.closest('.headerMenuMobileItem').classList.toggle('open');
    });
  }
}

// faq
const faqItemQuestions = document.querySelectorAll('.faqItemQuestion');
if (faqItemQuestions.length > 0) {
  for (const item of faqItemQuestions) {
    item.addEventListener('click', (e) => {
      item.closest('.faqItem').classList.toggle('open');
      item
        .closest('.faqItem')
        .querySelector('.faqItemAnswer')
        .classList.toggle('open');
    });
  }
}

// after_table
const mainTable = document.querySelector('.table');
const afterTable = document.querySelector('.afterTable');
const afterTableClose = document.querySelector('.afterTableClose');
if (afterTable && mainTable) {
  window.addEventListener('scroll', ()=>{
    if(window.scrollY > (mainTable.offsetTop + mainTable.scrollHeight - 200)){
      afterTable.classList.add('showed');
    }else {
      afterTable.classList.remove('showed');
    }
  })
  afterTableClose.addEventListener('click', () => {
    afterTable.style.display="none";
    document.querySelector('.afterTablePresent').classList.add('showed');
  });
}

// to top button
const goTopBtn = document.querySelector('#goTop');
window.addEventListener('scroll', trackScroll);
goTopBtn.addEventListener('click', goTop);

function trackScroll() {
  const scrolled = window.pageYOffset;
  const coords = document.documentElement.clientHeight;
  if (scrolled > coords) {
    goTopBtn.classList.add('active');
  } else {
    goTopBtn.classList.remove('active');
  }
}

function goTop() {
  if (window.pageYOffset > 0) {
    window.scrollBy(0, -75);
    setTimeout(goTop, 0);
  }
}

const ancors = document.querySelectorAll('.tocLink');
if (ancors.length > 0) {
  ancors.forEach((anchor) => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();
      const id = anchor.getAttribute('href');
      const yOffset = -60;
      const element = document.querySelector(id);
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    });
  });
}

// toc full
const tocFullTitle = document.querySelector('.tocFullTitle');
if (tocFullTitle) {
  tocFullTitle.addEventListener('click', () => {
    document.querySelector('.tocFullWrapper').classList.toggle('open');
    tocFullTitle.classList.toggle('open');
  });
}

// form
const contactForm = document.querySelector('#contactsForm');
const contactEmail = document.querySelector('#email');
const contactName = document.querySelector('#name');

function ValidateEmail() {
  const validRegexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (contactEmail.value.match(validRegexEmail)) {
    contactForm.email.classList.remove('invalid');
    return true;
  } else {
    contactForm.email.classList.add('invalid');
    contactForm.email.focus();
    return false;
  }
}

function ValidateName() {
  const validRegexEmail = /[A-Za-z]{3,20}/;
  if (contactName.value.match(validRegexEmail)) {
    contactForm.name.classList.remove('invalid');
    return true;
  } else {
    contactForm.name.classList.add('invalid');
    contactForm.name.focus();
    return false;
  }
}

if (contactForm) {
  contactEmail.addEventListener('change', ValidateEmail);
  contactName.addEventListener('change', ValidateName);
}

// cookie
const cookies = document.querySelector('#cookies');
const cookiesButton = document.querySelector('.cookieButton');

function getCookie(name) {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return !1;
}

function setCookie(name, value = !0, days = 99999) {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = days ? '; expires=' + date.toGMTString() : '';
  document.cookie = name + '=' + value + expires + '; path=/';
}

if (cookies) {
  cookiesButton.addEventListener('click', () => {
    cookies.classList.remove('show');
    setCookie('privacy-accepted', !0, 99999);
  });
  if (getCookie('privacy-accepted') == !1) {
    setTimeout(() => {
      cookies.classList.add('show');
    }, 4000);
  }
}

// bonus sort
const sortLabel = document.querySelector('.bonusesTableSortLabel');
if (sortLabel) {
  sortLabel.addEventListener('click', function () {
    let parent = sortLabel.closest('.bonusesTableSortField');
    parent.classList.toggle('open');
  });
}

// iframe game
const iframeGame = document.querySelector('#iframeGame');
const iframeReal = document.querySelector('.iframeReal');
const iframeFree = document.querySelector('.iframeFree');
if (iframeReal) {
  iframeReal.addEventListener('click', playIframe);
}
if (iframeFree) {
  iframeFree.addEventListener('click', playIframe);
}

function playIframe() {
  document.querySelector('.iframeBg').classList.toggle('hide');
  document.querySelector('.iframeButtons').classList.toggle('hide');
  const iframeSrc = iframeGame.getAttribute('data-game');
  document.querySelector(
    '.iframeBox'
  ).innerHTML += `<iframe src=${iframeSrc}  allow="autoplay; fullscreen; picture-in-picture"></iframe>`;
  document.querySelector('.iframeImg').classList.toggle('hide');
}

// fullscreen for iframe
const buttonFullScreen = document.querySelector('.metaInfoGameFullscreen');
if (buttonFullScreen) {
  buttonFullScreen.addEventListener('click', fullscreen);
}

// detect enter or exit fullscreen mode
document.addEventListener('webkitfullscreenchange', fullscreenChange);
document.addEventListener('mozfullscreenchange', fullscreenChange);
document.addEventListener('fullscreenchange', fullscreenChange);
document.addEventListener('MSFullscreenChange', fullscreenChange);

function fullscreen() {
  // check if fullscreen mode is available
  if (
    document.fullscreenEnabled ||
    document.webkitFullscreenEnabled ||
    document.mozFullScreenEnabled ||
    document.msFullscreenEnabled
  ) {
    const iframe = document.querySelector('#iframeGame');

    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe.webkitRequestFullscreen) {
      iframe.webkitRequestFullscreen();
    } else if (iframe.mozRequestFullScreen) {
      iframe.mozRequestFullScreen();
    } else if (iframe.msRequestFullscreen) {
      iframe.msRequestFullscreen();
    }
  }
}

function fullscreenChange() {
  if (
    document.fullscreenEnabled ||
    document.webkitIsFullScreen ||
    document.mozFullScreen ||
    document.msFullscreenElement
  ) {
  }
}

// modal Top3
const modalTop3Close = document.querySelector('.modalTop3Close');
if (modalTop3Close) {
  modalTop3Close.addEventListener('click', () => {
    document.querySelector('.modalTop3').classList.add('hide');
  });
}

// show more
const showMoreButtons = document.querySelectorAll('.textImgWithButtonButton');
if (showMoreButtons.length > 0) {
  for (let i = 0; i < showMoreButtons.length; i++) {
    showMoreButtons[i].addEventListener('click', () => {
      showMoreButtons[i]
        .closest('.textImgWithButton')
        .querySelector('.hide')
        .classList.remove('hide');
      showMoreButtons[i].classList.add('hide');
    });
  }
}

document.querySelectorAll('[data-read-more]')?.forEach((el) => {
  const btn = el.querySelector('[data-read-more-btn]');
  const txt = el.querySelector('[data-read-more-text]');

  btn.addEventListener('click', () => {
    let label = btn.textContent;
    btn.textContent = btn.getAttribute('data-label');
    btn.setAttribute('data-label', label);
    txt.classList[window.hasClass(txt, 'is-active') ? 'remove' : 'add'](
      'is-active'
    );
  });
});

const tablePlayDisc = document.querySelectorAll('.tablePlay span');
if (tablePlayDisc.length > 0) {
  for (let i = 0; i < tablePlayDisc.length; i++) {
    tablePlayDisc[i].addEventListener('click', () => {
      tablePlayDisc[i]
        .closest('.tablePlay')
        .querySelector('.tablePlayText')
        .classList.toggle('hide');
    });
  }
}
