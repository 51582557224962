window.addEventListener('DOMContentLoaded', () => {
    window.searchAndClosureSelector('.tocasino', (item) => {
        item.addEventListener('click', () => {
            window.clickToCasino(item);
        });
    }, true);

    window.searchAndClosureSelector('.tocasino-cta', (item) => {
        item.addEventListener('click', () => {
            let object = {},
                data = {
                    id: parseInt(item.getAttribute('data-ref_id')),
                    action: 'refdata_cta', nonce: hexjs.nonce,
                };

            window.sendRequest(data, 'POST', hexjs.ajax, {
                Accept: 'application/json', 'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
            }, (xhr) => {
                object = JSON.parse(xhr.response);

                if (object.link.length > 1) {
                    window.createAndSubmitForm(object);
                }
            });
        });
    }, true);
});